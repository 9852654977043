<template>
  <el-dialog class="customer-dialog" width="500px" :visible.sync="dialogFormVisible" :close-on-click-modal="false" :before-close="beforeClose" :fullscreen="dialogFull">
    <div slot="title">
      <div class="avue-crud__dialog__header">
        <span class="el-dialog__title">
          <!-- <span style="display:inline-block;background-color: #3478f5;width:3px;height:20px;margin-right:5px; float: left;margin-top:2px" /> -->
          设置筛选项
          <el-input
            v-model="inputSearch"
            style="margin-left:10px;width:180px;"
            placeholder="请输入内容"
            suffix-icon="el-icon-search"
            clearable
            @input="searchChange"
          />
        </span>
        <div class="avue-crud__dialog__menu" @click="dialogFull=!dialogFull">
          <i class="el-icon-full-screen" />
        </div>
      </div>

    </div>
    <div class="body">
      <draggable v-model="sourceArry" group="site" animation="300" drag-class="dragClass" ghost-class="ghostClass" chosen-class="chosenClass" @start="onStart" @end="onEnd">
        <transition-group>
          <!-- <div v-for="item in arr2" :key="item.id" class="item">{{ item.name }}</div> -->
          <div v-for="(item) in sourceArry" v-show="item.isVisible !== false" :key="item.FilterProp" class="dragitem" @click="item.Show=!item.Show">
            <el-tag v-if="item.Show">{{ item.FilterName }}</el-tag>
            <el-tag v-else type="info">{{ item.FilterName }}</el-tag>
          </div>
        </transition-group>
      </draggable>

    </div>
    <div slot="footer" class="dialog-footer">
      <div>
        <el-button size="mini" @click="initData()">重 置</el-button>
      </div>
      <div>
        <el-button size="mini" @click="changeData">取消</el-button>
        <el-button size="mini" style="margin-left:10px;" :loading="saveLoading" type="primary" @click="confirmHander(false)">保存</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import draggable from 'vuedraggable'
 import { GetCustomerFilterSetting, SaveFilterSetting } from '@/api/client'

export default {
  name: 'SetFilter',
  // 注册draggable组件
  components: {
    draggable
  },
  props: {
    filterCode: {
      type: String,
      required: true
    },
    dialogFormVisible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      sysArry: [], // 系统列
      sourceArry: [
      ], // 拖拽列的源
      checkArry: [], // 选择的列
      inputSearch: '',
      dialogFull: false,
      saveLoading: false
    }
  },
  mounted() {
  },
  beforeDestroy() {
  },
  async created() {
      console.log(this.filterCode)
    await this.getCustomColumn()
    this.resetData()
  },
  methods: {
    // 获取自定义列
    async getCustomColumn() {
      const requestdata = {
        'FilterType': this.filterCode
      }
      const data = await GetCustomerFilterSetting(requestdata)
      console.log(data)
      this.CurrentColumns = data.CustomFilters.length >0? data.CustomFilters:data.DefaultFilters
      this.SysColumns = data.DefaultFilters
      this.setData()
    },
    // 设置初始数据
    setData() {
      if(this.CurrentColumns){
        this.sourceArry = this.deepClone(this.CurrentColumns)
        this.sysArry = this.deepClone(this.SysColumns) // Object.assign([], this.SysColumns)
        this.sysArry.forEach(a => {
            const result = this.sourceArry.find(ele => ele.FilterProp === a.FilterProp)
            if (!result) {
            a.Show = false
            this.sourceArry.push(a)
            }
        })
      }
    },

    // 点击footer中的取消按钮--通过方法请求给父组件改变值
    changeData() {
      // this.$emit('editColsDialogChangeP', false)
      this.closeDialog()
    },
    // 点击右上角关闭
    beforeClose(done) {
      // this.$emit('editColsDialogChangeP', false)
      this.closeDialog()
    },
    // 开始拖拽事件
    onStart() {
      this.drag = true
    },
    // 拖拽结束事件
    onEnd() {
      this.drag = false
    },
    // 搜索
    searchChange() {
      this.sourceArry.forEach(a => {
        if (this.inputSearch === null || this.inputSearch === '') a.isVisible = true
        else if (a.FilterName.indexOf(this.inputSearch) < 0) a.isVisible = false
      })
    },
    // 确定按钮
    async confirmHander() {
      this.checkArry = []
      this.sourceArry.forEach(a => {
        if (a.Show) { this.checkArry.push(a) }
      })
      // this.$emit('confirmHanderP', this.checkArry)
     // this.showSaveLoading(true)
      var requestData = {
        'FilterType': this.filterCode,
        'Filters': this.checkArry
      }
      console.log(requestData)
      const res = await SaveFilterSetting(requestData)
      this.showSaveLoading(false)
      if (res) {
        this.$message.success('成功')
        
        // this.$EventBus.$emit(this.filterCode)
        // this.$emit('editColsDialogChangeP', false)
        this.closeDialog()
        this.$parent.onInit()
      } else {
        this.$message.error('失败')
      }
    },
    // 重置
    resetData() {
      this.setData()
    },
    // 初始化
    initData() {
      console.log(this.sourceArry)
      this.sourceArry = this.deepClone(this.SysColumns)
    },
    // 保存按钮控制loading
    showSaveLoading(params) {
      this.saveLoading = params
    },
    deepClone(obj) {
      return JSON.parse(JSON.stringify(obj))
    },
    closeDialog() {
      this.$parent.setFilterFalg = false
    }
  }
}
</script>

<style lang="scss" scoped>
.my-autocomplete {
    li {
        line-height: normal;
        padding: 7px;

        .name {
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .addr {
        font-size: 12px;
        color: #b4b4b4;
        }

        .highlighted .addr {
        color: #ddd;
        }
    }
}
.dragitem{
    display: inline-block;
    margin: 10px 10px 0 0;
    cursor: move;
}
.dragClass{
    cursor: move;
}
.dialog-footer {
  display: flex;
  justify-content: space-between;
}
.body {
  min-height: 150px;
  max-height: 300px;
}
#app .customer-dialog .el-dialog{
    .el-dialog{min-width: 200px;}
}
.customer-dialog{
  /*dialog header*/
  .avue-crud__dialog__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: -1px;
  }
  .el-dialog__title {
    color: rgba(0,0,0,.85);
    font-weight: 500;
    word-wrap: break-word;
  }
  .avue-crud__dialog__menu {
    padding-right: 25px;
    float: left;
  }
  .avue-crud__dialog__menu i {
    color: #909399;
    font-size: 15px;
  }
  .el-icon-full-screen{
    cursor: pointer;
  }
}
</style>
