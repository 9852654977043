<template>
  <div class="m-grid p-client-list" style="overflow-y: auto">

    <section class="p-client-list-search">
      <!--      点击搜索-->
      <div v-if="selectItemList.length" class="p-client-list-search_select">
        <p class="p-client-list-search_select-title">
          <span>已选属性/标签: </span>
        </p>
        <div class="p-client-list-search_select-box">
          <p
            v-for="(item, index) in selectItemList"
            :key="index"
            :title="`${item.itemName}: ${item.selectValue}`"
          >
            <span
              >{{ item.itemName }}：<b>{{ item.selectValue }}</b></span
            >
            <i @click="onDelSelect(item)">&#10005;</i>
          </p>
        </div>
      </div>

      <div class="p-client-list-search-box p-client-list-search_box-total">
        <p class="p-client-list-search_title">
          <span>客户类型</span>
        </p>
        <div class="p-client-list-search_ctn">
          <p
            v-for="(item, index) in clientTypeList"
            :key="index"
            :class="isSelectClient === index ? 'isSelectClient' : ''"
            @click="onClientTypeSelect(item, index)"
          >
            <span>{{ item.name }}</span>
            <i>({{ item.value }})</i>
          </p>
        </div>
        <div class="p-client-list-search_option"></div>
      </div>
      <div
        :class="showMap.isBaseShow ? 'p-ck_search-box' : ''"
        class="p-client-list-search-box"
      >
        <div class="p-client-list-search_title">
          <span>基础资料</span>
        </div>
        <div class="p-client-list-search_ctn">
          <p
            v-for="(item, index) in baseInfoList"
            :key="index"
            style="display: inline-block"
          >
            <znl-input
              v-model="selectBaseInfo[item.FilterProp]"
              v-show="item.Show"
              :placeholder="item.FilterName"
              border
              class="p-client-list-search_input"
              clearable
              width="130px"
            ></znl-input>
          </p>
        </div>
        <div class="p-client-list-search_option">
          <span class="p-client-list-search_option--span">
            <i @click="onShow('isBaseShow')">收起</i>
            <i
              v-if="showMap.isBaseShow"
              class="el-icon-arrow-up p-client-list-search_option--ccc"
            ></i>
            <i
              v-else
              class="el-icon-arrow-down p-client-list-search_option--ccc"
            ></i>
          </span>
          <span>
            <i @click="setFilter('base_info')">设置</i>
            <i class="el-icon-setting"></i>
          </span>
        </div>
      </div>
      <div
        v-if="companyList.length"
        :class="showMap.isCompanyShow ? 'p-ck_search-box' : ''"
        class="p-client-list-search-box"
      >
        <div class="p-client-list-search_title">
          <span>公司属性</span>
        </div>
        <div class="p-client-list-search_ctn">
          <select-input
            v-for="item in companyList"
            v-show="item.Show"
            :key="item.itemId"
            :item="item"
            @selectItems="selectTagAndAttribute"
          ></select-input>
        </div>
        <div class="p-client-list-search_option">
          <span class="p-client-list-search_option--span">
            <i @click="onShow('isCompanyShow')">收起</i>
            <i
              v-if="showMap.isCompanyShow"
              class="el-icon-arrow-up p-client-list-search_option--ccc"
            ></i>
            <i
              v-else
              class="el-icon-arrow-down p-client-list-search_option--ccc"
            ></i>
          </span>
          <span>
            <i @click="setFilter('attr_info')">设置</i>
            <i class="el-icon-setting"></i>
          </span>
        </div>
      </div>
      <div
        v-if="contactsList.length"
        :class="showMap.isContactsShow ? 'p-ck_search-box' : ''"
        class="p-client-list-search-box"
      >
        <div class="p-client-list-search_title">
          <span>联系人标签</span>
        </div>
        <div class="p-client-list-search_ctn">
          <select-input
            v-for="item in contactsList"
            v-show="item.Show"  
            :key="item.itemId"
            :item="item"
            @selectItems="selectTagAndAttribute"
          ></select-input>
        </div>
        <div class="p-client-list-search_option">
          <span class="p-client-list-search_option--span">
            <i @click="onShow('isContactsShow')">收起</i>
            <i
              v-if="showMap.isContactsShow"
              class="el-icon-arrow-up p-client-list-search_option--ccc"
            ></i>
            <i
              v-else
              class="el-icon-arrow-down p-client-list-search_option--ccc"
            ></i>
          </span>
          <span>
            <i @click="setFilter('label_info')">设置</i>
            <i class="el-icon-setting"></i>
          </span>
        </div>
      </div>
    </section>
    <div class="m-grid-search">
      <znl-input
        v-model="searchData.subordinateName"
        :select-options="systemUser"
        border
        class="m-margin--right-10"
        clearable
        form-type="select"
        placeholder="所属人员"
        width="150"
      >
      </znl-input>
      <znl-input
        v-model="searchData.sortIndex"
        :select-options="sortMap"
        border
        class="m-margin--right-10"
        clearable
        form-type="select"
        placeholder="排列顺序"
        width="150"
      ></znl-input>
      <znl-input
        v-model="searchData.isUse"
        :select-options="clientIsUseOption"
        border
        class="m-margin--right-10"
        clearable
        form-type="select"
        placeholder="启用状态"
        width="150"
      ></znl-input>
      <el-button class="m-grid-btn" type="primary" @click="onSearch()"
        >查询
      </el-button>
      <el-button class="m-grid-btn" plain type="primary" @click="onRes"
        >重置</el-button
      >
    </div>
    <div class="m-grid-search">

      <el-button
    
        class="m-grid-btn"
        type="primary"
        @click="onAddClient"
        >新增</el-button
      >
      <div class="elbtn"  v-can="'client_manage_list:delete'">
        <el-button
       
        class="m-grid-btn"
        type="primary"
        @click="onDeleteClient"
        >删除
      </el-button>
      </div>
      
      <el-button
        v-can="'client_manage_list:add_common'"
        class="m-grid-btn"
        type="primary"
        @click="onAddCommon"
        >加入公海
      </el-button>
      <el-button
        v-can="'client_manage_list:merge_client'"
        class="m-grid-btn"
        type="primary"
        @click="onMergeClient"
      >
        合并客户
      </el-button>
      <el-button
        v-can="'client_manage_list:change_type'"
        class="m-grid-btn"
        type="primary"
        @click="onChangeType"
      >
        更改客户类型
      </el-button>
      <el-button
          v-can="'client_manage_list:change_company_sales'"
          class="m-grid-btn"
          type="primary"
          @click="onChangeCompanySales"
      >
        修改客户归属人
      </el-button>
      <el-button
          class="m-grid-btn"
          type="primary"
          @click="onExport"
      >
        导出客户
      </el-button>
      <div class="m-grid-btn" style="margin-left: 10px">
        <el-upload
            ref="upload"
            action=""
            :limit="1"
            :show-file-list="false"
            :http-request="uploadImgs"
            accept=".xls,.xlsx"
            :file-list="fileList">
          <el-button
              class="m-grid-btn"
              type="primary"
              slot="trigger"
          >
            导入客户
          </el-button>
        </el-upload>
      </div>
    </div>
    <section class="m-grid-list p-client-list-bd">
      <grid
        ref="list"
        :columns="columns"
        :datas="itemSource"
        :onPageChanged="onSearch"
        :page-index="pagination.page"
        :page-size="pagination.limit"
        :selection="true"
        :total-count="pagination.total"
        headerHeight="35"
        type="base"
        @onCellClicked="onCellClicked"
        @onColumnMoved="onColumnMoved"
        @onCellDoubleClicked="onCellDoubleClicked"
      >
      </grid>
    </section>

    <merge-client-view
      ref="mergeClient"
      @onSearch="onSearch"
    ></merge-client-view>
    <change-type-view
      ref="changeTypeView"
      :clientTypeList="clientTypeList"
      @onSearch="onSearch"
    ></change-type-view>
    <ChangeCompanySales ref="changeCompanySales"/>
    <rate-change-view
      ref="rateChangeView"
      @onSearch="onSearch"
    ></rate-change-view>
    <add-common-view ref="addCommonView" @onSearch="onSearch"></add-common-view>
    <set-filter
      v-if="setFilterFalg"
      :filter-code="filterItemCode"
    />
  </div>
</template>

<script>
import { columns, baseInfoList } from "./Modules/List/config";
import { sortMap } from "./Modules/List/dataMap";
import SelectInput from "@/components/SelectInput/index.vue";
import ChangeTypeView from "./Modules/List/ChangeTypeView.vue";
import ChangeCompanySales from "./Modules/List/ChangeCompanySales.vue";
import AddCommonView from "./Modules/AddCommonView.vue";
import SetFilter from "@/components/SetFilter/index.vue";
import {
  getClientList,
  getClientType,
  GetSocresInfo,
  getTagAndAttribute,
  deleteClient,
  GetCustomerFilterSetting, exportCustomer, importCustomer, OnUploadPicture
} from "../../api/client";
import { downFile } from "../../api/common.js";
import MergeClientView from "./Modules/List/MergeClientView.vue";
import RateChangeView from "./Modules/List/RateChangeView.vue";

import { userColumnMixin } from "@/components/Mixins/UserColumnMixin"

const TableName = 'customer-list'

export default {
  name: "clientList",
  mixins: [userColumnMixin],
  components: {
    SelectInput,
    MergeClientView,
    ChangeTypeView,
    RateChangeView,
    AddCommonView,
    ChangeCompanySales,
    SetFilter
  },
  computed: {
    systemUser() {
      const data = this.$store.state.common.SystemUser;

      if (data) {
        return data.map((item) => {
          return {
            key: item.UserID,
            value: item.UserName,
          };
        });
      } else {
        return [];
      }
    },

    isAllBtn() {
      const accessPoint = [
        "client_manage_list:add",
        "client_manage_list:delete",
        "client_manage_list:add_common",
        "client_manage_list:merge_client",
        "client_manage_list:change_type",
      ];
      return accessPoint.some((item) => this.can(item));
    },
  },
  data() {
    return {
      selectTitle:'',
      selectSort:0,
      activeName: 'first',
      columns: columns,
      itemSource: [],
      fileList: [],
      pagination: {
        page: 1,
        limit: 20,
        total: 40,
      },
      showMap: {
        isBaseShow: false,
        isCompanyShow: false,
        isContactsShow: false,
      },
      isShow: false,
      // 客户类型
      clientTypeList: [],
      isSelectClient: 0,
      selectClientType: {},

      // 基础资料
      baseInfoList: [], // baseInfoList,
      selectBaseInfo: {},

      // 公司属性
      companyList: [],
      companyListFilter: [],
      initCompanyList: [],

      // 联系人标签
      contactsList: [],
      contactsListFilter: [],
      initContactList: [],

      // 已选择的标签
      selectItemList: [],

      searchData: {
        subordinateName: "",
        sortIndex: "",
        isUse: "",
      },
      clientIsUseOption: [
        {
          key: 1,
          value: "禁用",
        },
        {
          key: 2,
          value: "启用",
        },
      ],
      sortMap: sortMap,
      setFilterFalg: false, // 是否显示设置筛选项弹窗
      filterItemCode:'' // 筛选项Code
    };
  },
  methods: {
    async isBtn(name) {
      const has = await this.can(name);
      return has;
    },
    onInit() {
      Promise.all([
        this.getFillter(),
        this.getAttrlFillter(),
        this.getLabelFillter(),
      ]).then(res => {
        this.setTagAndAttribute();
      })
      this.setClientType();
    },
    // 获取选择列表数据
    // 客户类型数量和表头满分
    async getClientType() {
      return await getClientType();
    },
    async getTagAndAttribute() {
      return await getTagAndAttribute();
    },
    // 获得基础资料条件设置条件
    async getFillter(){
      const requestdata = {
        FilterType: "base_info"
      }
      const data = await GetCustomerFilterSetting(requestdata)
      this.baseInfoList=data.CustomFilters.length>0 ? data.CustomFilters:data.DefaultFilters
    },
    async getAttrlFillter(){
        const attr_data=await GetCustomerFilterSetting({
          FilterType: "attr_info"
        })
        this.companyListFilter=attr_data.CustomFilters.length>0 ? attr_data.CustomFilters:attr_data.DefaultFilters
    },
    async getLabelFillter(){
        const label_data=await GetCustomerFilterSetting({
          FilterType: "label_info"
        })
        
        this.contactsListFilter=label_data.CustomFilters.length>0 ? label_data.CustomFilters:label_data.DefaultFilters

    },
    // 处理选择列表数据
    // 客户类型
    async setClientType() {
      const data = await this.getClientType();
      const { CustomerTypes } = data;
      let clientTotal = 0;
      CustomerTypes.forEach((item) => {
        clientTotal += item["Count"];
      });
      this.clientTypeList = CustomerTypes.map((item) =>
        Object.assign(
          {},
          {
            name: item["CustomerTypeName"],
            value: item["Count"],
            type: item["CustomerType"],
          }
        )
      );
      this.clientTypeList.unshift({
        name: "全部客户",
        value: clientTotal,
        type: "",
      });

      // 初始化
      this.selectClientType = this.clientTypeList[0];
    },
    onClientTypeSelect(selectItem, index) {
      this.isSelectClient = index;
      this.selectClientType = selectItem;

      this.onSearch(this.pagination.page);
    },

    // 客户属性,标签
    async setTagAndAttribute() {
      const data = await this.getTagAndAttribute();
      const tagList = data.filter(
        (item) => item["DictItemType"] === "Attribute"
      );
      const attributeList = data.filter(
        (item) => item["DictItemType"] === "Lable"
      );
      const [...companyList] = this.setTagAndAttributeList(tagList, 3);
      const [...contactsList] = this.setTagAndAttributeList(attributeList, 2);
      const initcompanyList = this.setTagAndAttributeList(tagList, 3);

      const initcontactsList = this.setTagAndAttributeList(attributeList, 2);
      this.companyList = initcompanyList.map((item,index) => {
          let findData=this.companyListFilter.find(a=> a.FilterProp===item.itemCode)
          if(!findData) {
            findData = {OrderBy: 0,Show: true}
          }
          const {OrderBy, Show}=findData
          return {...item, OrderBy, Show};
      });
      this.companyList.sort(function(a, b) {
          return a.OrderBy - b.OrderBy;
      });

      this.contactsList = initcontactsList.map((item,index) => {
          let findData = this.contactsListFilter.find(a=> a.FilterProp===item.itemCode)
          if(!findData) {
            findData = {OrderBy: 0,Show: true}
          }
          const {OrderBy, Show}=findData
          return {...item, OrderBy, Show};
      });

      this.contactsList.sort(function(a, b) {
          return a.OrderBy - b.OrderBy;
      });

      // 储存原始数据
      this.initCompanyList = companyList;
      this.initContactList = contactsList;
    },
    setTagAndAttributeList(list, zIndex) {
      // 整理数据
      return list.map((item, index) =>
        Object.assign(
          {},
          {
            zIndex: zIndex,
            indexId: index + 1,
            itemId: item["DictItemId"],
            itemName: item["DictItemName"],
            itemType: item["DictItemType"],
            itemCode: item["DictItemCode"],
            lines: item["DictItemLines"].map((line) =>
              Object.assign(
                {},
                {
                  itemId: item["DictItemId"],
                  itemName: item["DictItemName"],
                  lineId: line["DictItemLineId"],
                  lineName: line["DisctItemLineName"],
                  lineCode: line["DisctItemLineCode"],
                }
              )
            ),
          }
        )
      );
    },

    // 选择标签, 属性
    selectTagAndAttribute(item) {
     
      const { lines } = item;
      const selectLines = lines.map((line) => line.lineName);
      item.selectValue = selectLines.join(",");
      this.selectItemList.push(Object.assign({}, item));

      this.setSelectTagAndAttribute();
    },

    // 删除已选择数据
    onDelSelect(item) {
      this.selectItemList = this.selectItemList.filter(
        (m) => !(m.indexId === item.indexId && m.itemType === item.itemType)
      );
      this.setSelectTagAndAttribute();
    },

    // 处理选择后的选项
    setSelectTagAndAttribute() {
      this.companyList = [];
      this.contactsList = [];

      const { selectItemList } = this;
      const selectCompanyList = selectItemList.filter(
        (item) => item.itemType === "Attribute"
      );
      const selectContactList = selectItemList.filter(
        (item) => item.itemType === "Lable"
      );

      const selectCompanyListIds = selectCompanyList.map((m) => m.indexId);
      const selectContactListIds = selectContactList.map((m) => m.indexId);

      const [...newInitCompanyList] = this.initCompanyList;
      const [...newInitContactList] = this.initContactList;


      this.companyList = newInitCompanyList.filter(
        (m) => !selectCompanyListIds.includes(m.indexId)
      );
      this.contactsList = newInitContactList.filter(
        (m) => !selectContactListIds.includes(m.indexId)
      );
      this.companyList = this.companyList.map((item,index) => {
          const {OrderBy, Show}=this.companyListFilter.find(a=> a.FilterProp===item.itemCode)
          return {...item, OrderBy, Show};
      });
  
      this.contactsList = this.contactsList.map((item,index) => {
          const {OrderBy, Show}=this.contactsListFilter.find(a=> a.FilterProp===item.itemCode)
          return {...item, OrderBy, Show};
      });

       this.companyList.sort(function(a, b) {
          return a.OrderBy - b.OrderBy;
      });

      this.contactsList.sort(function(a, b) {
          return a.OrderBy - b.OrderBy;
      });
    },

    // 处理搜索参数

    async onRes() {
      this.selectBaseInfo = {};
      this.selectClientType = {};
      this.selectItemList = [];
      this.isSelectClient = 0;
      // 重置为原始数据
      this.companyList = this.initCompanyList;
      this.contactsList = this.initContactList;
      Object.assign(this.$data.searchData, this.$options.data().searchData);
      Object.assign(this.$data.pagination, this.$options.data().pagination);
      await this.onSearch();
    },
    setParams() {
      const { selectBaseInfo, selectClientType, selectItemList } = this;

      let params = {};
      params.BelongSort = this.searchData.sortIndex; //排序
      params.Status = this.searchData.isUse; //状态
      params.BelongUser = this.searchData.subordinateName; //人员

      params.CustomerType = selectClientType.type;
      Object.keys(selectBaseInfo).forEach(
        (key) => (params[key] = selectBaseInfo[key])
      );

      const attributeData = {};
      const tagData = {};

      if (selectItemList.length !== 0) {
        selectItemList.forEach((item) => {
          if (item.itemType === "Attribute") {
            attributeData[item.itemCode] = item.lines.map(
              (line) => line.lineCode
            );
          }
          if (item.itemType === "Lable") {
            tagData[item.itemCode] = item.lines.map((line) => line.lineCode);
          }
        });

        params.CustomerAttributes = attributeData;
        params.ContactLabels = tagData;
      }

      return params;
    },

    async onSearch(page = 1,a) {
      const { pagination } = this;
      let sort={
        title:this.selectTitle,
        state:this.selectSort
      };
      let sendData = {
        Page: page,
        Limit: pagination.limit,
        SortDate: sort,
        title:a,
        FirstType:-1,
        LastType:-1
      };

      sendData = Object.assign(sendData, this.setParams())
      const data = await getClientList(sendData);
      // if (a == "wxj" && sort.state == 0) {
      //   var arr = data.Items;
      //   for (var i = 0; i < arr.length - 1; i++) {
      //     for (var j = 0; j < arr.length - 1 - i; j++) {
      //       if(arr[j].NoInquiryDaysP !=null)
      //       {
      //         if (arr[j].NoInquiryDaysP > arr[j + 1].NoInquiryDaysP) {
      //         var temp = arr[j];
      //         arr[j] = arr[j + 1];
      //         arr[j + 1] = temp;

      //       }
      //       }

      //     }
      //   }
      //    ;
      //   pagination.page = data.Page;
      //   pagination.total = data.Total;
      //   this.itemSource = arr;
      // }
      // if (a == "wxj" && sort.state == 1) {
      //   var arr = data.Items;
      //   console.log(arr.length)
      //   for (var i = 0; i < arr.length - 1; i++) {
      //     for (var j = 0; j < arr.length - 1 - i; j++) {
      //       if (arr[j].NoInquiryDaysP < arr[j + 1].NoInquiryDaysP) {
      //         var temp = arr[j];
      //         arr[j] = arr[j + 1];
      //         arr[j + 1] = temp;
      //       }
      //     }
      //   }
      //    ;
      //   pagination.page = data.Page;
      //   pagination.total = data.Total;
      //   this.itemSource = arr;
      // }
      // if (a == "wxd" && sort.state == 0) {
      //   var arr = data.Items;
      //   for (var i = 0; i < arr.length - 1; i++) {
      //     for (var j = 0; j < arr.length - 1 - i; j++) {
      //       if (arr[j].NoOrderDaysP > arr[j + 1].NoOrderDaysP) {
      //         var temp = arr[j];
      //         arr[j] = arr[j + 1];
      //         arr[j + 1] = temp;
      //       }
      //     }
      //   }
      //    ;
      //   pagination.page = data.Page;
      //   pagination.total = data.Total;
      //   this.itemSource = arr;
      // }
      // if (a == "wxd" && sort.state == 1) {
      //   var arr = data.Items;
      //   for (var i = 0; i < arr.length - 1; i++) {
      //     for (var j = 0; j < arr.length - 1 - i; j++) {
      //       if (arr[j].NoOrderDaysP < arr[j + 1].NoOrderDaysP) {
      //         var temp = arr[j];
      //         arr[j] = arr[j + 1];
      //         arr[j + 1] = temp;
      //       }
      //     }
      //   }
      //    ;
      //   pagination.page = data.Page;
      //   pagination.total = data.Total;
      //   this.itemSource = arr;
      // }
      if (a == "wxdghdjs" && sort.state == 0) {
        var arr = data.Items;
        for (var i = 0; i < arr.length - 1; i++) {
          for (var j = 0; j < arr.length - 1 - i; j++) {
            if (arr[j].NoOrderCountdownP > arr[j + 1].NoOrderCountdownP) {
              var temp = arr[j];
              arr[j] = arr[j + 1];
              arr[j + 1] = temp;
            }
          }
        }
         ;
        pagination.page = data.Page;
        pagination.total = data.Total;
        this.itemSource = arr;
      }
      if (a == "wxdghdjs" && sort.state == 1) {
        var arr = data.Items;
        for (var i = 0; i < arr.length - 1; i++) {
          for (var j = 0; j < arr.length - 1 - i; j++) {
            if (arr[j].NoOrderCountdownP < arr[j + 1].NoOrderCountdownP) {
              var temp = arr[j];
              arr[j] = arr[j + 1];
              arr[j + 1] = temp;
            }
          }
        }
         ;
        pagination.page = data.Page;
        pagination.total = data.Total;
        this.itemSource = arr;
      }
      if (a == "wxhghdjs" && sort.state == 0) {
        var arr = data.Items;
        for (var i = 0; i < arr.length - 1; i++) {
          for (var j = 0; j < arr.length - 1 - i; j++) {
            if (arr[j].NoInquiredCountdownP > arr[j + 1].NoInquiredCountdownP) {
              var temp = arr[j];
              arr[j] = arr[j + 1];
              arr[j + 1] = temp;
            }
          }
        }
         ;
        pagination.page = data.Page;
        pagination.total = data.Total;
        this.itemSource = arr;
      }
      if (a == "wxhghdjs" && sort.state == 1) {
        var arr = data.Items;
        for (var i = 0; i < arr.length - 1; i++) {
          for (var j = 0; j < arr.length - 1 - i; j++) {
            if (arr[j].NoInquiredCountdownP < arr[j + 1].NoInquiredCountdownP) {
              var temp = arr[j];
              arr[j] = arr[j + 1];
              arr[j + 1] = temp;
            }
          }
        }
         ;
        pagination.page = data.Page;
        pagination.total = data.Total;
        this.itemSource = arr;
      }
      if (a == "wgjghdjs" && sort.state == 0) {
        var arr = data.Items;
        for (var i = 0; i < arr.length - 1; i++) {
          for (var j = 0; j < arr.length - 1 - i; j++) {
            if (arr[j].NofollowCountdownP > arr[j + 1].NofollowCountdownP) {
              var temp = arr[j];
              arr[j] = arr[j + 1];
              arr[j + 1] = temp;
            }
          }
        }
         ;
        pagination.page = data.Page;
        pagination.total = data.Total;
        this.itemSource = arr;
      }
      if (a == "wgjghdjs" && sort.state == 1) {
        var arr = data.Items;
        for (var i = 0; i < arr.length - 1; i++) {
          for (var j = 0; j < arr.length - 1 - i; j++) {
            if (arr[j].NofollowCountdownP < arr[j + 1].NofollowCountdownP) {
              var temp = arr[j];
              arr[j] = arr[j + 1];
              arr[j + 1] = temp;
            }
          }
        }
         ;
        pagination.page = data.Page;
        pagination.total = data.Total;
        this.itemSource = arr;
      }
      else {
        pagination.page = data.Page;
        pagination.total = data.Total;
        this.itemSource = data.Items;
      }
    },
    onShow(key) {
      this.showMap[key] = !this.showMap[key];
    },
    setFilter(key){
      this.filterItemCode=key
      this.setFilterFalg=!this.setFilterFalg
    },
    // 操作
    // 新增
    onAddClient() {
      this.$router.push({
        name: "newClient",
      });
    },
    // 修改
    onEditClient() {
      const selectRow = this.$refs.list.getSelectedRows();
      const length = selectRow.length;
      let err = null;

      if (length === 0) {
        err = "请选择一条数据";
      }
      if (length > 1) {
        err = "一次只能修改一条数据";
      }

      if (err) {
        this.$message.warning(err);
        return false;
      }

      const data = selectRow[0];
      this.$router.push({
        name: "editClient",
        params: {
          clientId: data.CompanyID,
        },
        query: {
          id: data.CompanyID,
        },
      });
    },
    onDeleteClient() {
      const selectRow = this.$refs.list.getSelectedRows();
      const length = selectRow.length;
      let err = null;
      if (length === 0) {
        err = "请选择一条数据";
      }

      if (err) {
        this.$message.warning(err);
        return false;
      }

      const ids = selectRow.map((item) => item.CompanyID);
      this.$confirm("此操作将永久删除该客户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await deleteClient(ids);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          await this.onSearch();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    // 加入公海
    onAddCommon() {
      const selectRow = this.$refs.list.getSelectedRows();
      if (selectRow.length === 0) {
        this.$message.warning("请选择一条数据");
        return;
      }

      const data = selectRow.map((item, index) => {
        return {
          indexRow: index + 1,
          id: item.CompanyID,
          clientName: item.CompanyName,
          isSelect: true,
        };
      });

      this.$refs.addCommonView.onOpen(data);
    },
    // 保护
    async onProtect() {
      const selectRow = this.$refs.list.getSelectedRows();
      if (selectRow.length === 0) {
        this.$message.warning("请选择一条数据");
      }
      const params = {
        CompanyIds: selectRow.map((x) => x.Id),
      };
    },
    // 分配
    onAllocation() {},
    onTransfer() {},
    onRinse() {},
    onMergeClient() {
      const selectRows = this.$refs.list.getSelectedRows();
      if (selectRows.length <= 1) {
        this.$message.warning("请至少选择两条数据");
        return false;
      }
      const ids = selectRows.map((item) => item["CompanyID"]);

      this.$router.push({
        name: "mergeClient",
        query: {
          ids: ids,
        },
      });
    },
    onChangeType() {
      const selectRow = this.$refs.list.getSelectedRows();
      const length = selectRow.length;

      let err = "";
      if (length === 0) {
        err = "请选择一条数据";
      }
      if (length > 1) {
        err = "只能更改一条数据";
      }
      if (err) {
        this.$message.warning(err);
        return false;
      }

      const data = selectRow;
      this.$refs.changeTypeView.onOpen(data);
    },
    onChangeCompanySales() {
      const selectRows = this.$refs.list.getSelectedRows();
      if (selectRows.length === 0) {
        this.$message.warning("请至少选择一条数据");
        return false;
      }
      const CompanyIds = selectRows.map((item) => item["CompanyID"]);
      this.$refs.changeCompanySales.onOpen(CompanyIds);
    },

    onExport() {
      let sort={
        title:this.selectTitle,
        state:this.selectSort
      };
      let sendData = {
        SortDate: sort,
        FirstType:-1,
        LastType:-1
      };

      sendData = Object.assign(sendData, this.setParams())
      const baseFileUrl = this.$originUrl()
      exportCustomer(sendData).then(url => {
        const path = baseFileUrl + '/' +  url;
        downFile({ FileName: '客户列表.xlsx', FileUrl: path })
      });
    },
    uploadImgs(files, uploadImgList) {
      this.upEvent(files.file)
    },
    async upEvent(file) {
      const formData = new FormData()
      formData.set('file', file)
      try {
        await importCustomer(formData)
        this.$message.success('导入成功');
      } finally {
        this.$refs.upload.clearFiles()
      }
    },
    onImport() {

    },
    // 初始化表格
    async initColumns() {
      const self = this;
      const OriginColumn = columns.find((item) => item.field === "operation");

      const RateColumn = columns.find((item) => item.field === "EmergencyLevel");
      const LastFolowUpTime = columns.find((item) => item.field === "LastFolowUpTime");
      const LastOrderTime = columns.find((item) => item.field === "LastOrderTime");
      const LastInquireTime = columns.find((item) => item.field === "LastInquireTime");
      const NoOrderDaysP = columns.find((item) => item.field === "NoOrderDaysP");
      const NoInquiryDaysP = columns.find((item) => item.field === "NoInquiryDaysP");
      const NoOrderCountdownP = columns.find((item) => item.field === "NoOrderCountdownP");
      const NoInquiredCountdownP = columns.find((item) => item.field === "NoInquiredCountdownP");
      const NofollowCountdownP = columns.find((item) => item.field === "NofollowCountdownP");
      const CreatedTime = columns.find((item) => item.field === "CreatedTime");

      const ScoreAttribute = columns.find((item) => item.field === "ScoreAttribute");
      const ScoreLabel = columns.find((item) => item.field === "ScoreLabel");
      const ScoreBaseInfo = columns.find((item) => item.field === "ScoreBaseInfo");

      if (OriginColumn) {
        OriginColumn.cellRendererParams = {
          buttons: self.setButtons,
        };
      }
      if (RateColumn) {
        RateColumn.cellRendererParams = {
          // buttons: self.setButtons,
        };
      }

      const scoresinfo=await GetSocresInfo();

      // const ScoreBaseInfos=scoresinfo.ScoreBaseInfo
      // const ScoreAttributes=scoresinfo.ScoreAttribute
      // const ScoreLabels=scoresinfo.ScoreLabel

      //标签分
      if (ScoreLabel) {
        const ScoreInfos=scoresinfo.ScoreLabel
        ScoreLabel.headerComponentParams = {
          ScoreInfos
        };
      }
      //属性分
      if (ScoreAttribute) {
        const ScoreInfos=scoresinfo.ScoreAttribute
        ScoreAttribute.headerComponentParams = {
          ScoreInfos
        };
      }
      //基础分
      if (ScoreBaseInfo) {
        const ScoreInfos=scoresinfo.ScoreBaseInfo
        ScoreBaseInfo.headerComponentParams = {
          ScoreInfos
        };
      }
      // 创建时间
      if (CreatedTime) {
        CreatedTime.headerComponentParams = {
          click: (data) => {
            let page = 1;
            this.selectTitle=data.title
            this.selectSort=data.state
            this.onSearch(page);
          },
        };
      }

      // 跟进时间
      if (LastFolowUpTime) {
        LastFolowUpTime.headerComponentParams = {
          click: (data) => {
            let page = 1;
            this.selectTitle=data.title
            this.selectSort=data.state
            this.onSearch(page);
          },
        };
      }

      // 最近下单时间
      if (LastOrderTime) {
        LastOrderTime.headerComponentParams = {
          click: (data) => {
            let page = 1;
            this.selectTitle=data.title
            this.selectSort=data.state
            this.onSearch(page);
          },
        };
      }

       // 最后询价时间
      if(LastInquireTime){
         LastInquireTime.headerComponentParams = {
            click:(data)=>{
              let page=1;
            this.selectTitle=data.title
            this.selectSort=data.state
             this.onSearch(page)
            }
         }

      // 未询价天数
      if (NoInquiryDaysP) {
        NoInquiryDaysP.headerComponentParams = {
          click: (data) => {
            let page = 1;
            this.selectTitle=data.title
            this.selectSort=data.state
            this.onSearch(page);
          },
        };
      }

      // 未下单天数
      if (NoOrderDaysP) {
        NoOrderDaysP.headerComponentParams = {
          click: (data) => {
            let page = 1;
             this.selectTitle=data.title
            this.selectSort=data.state
            this.onSearch(page);
          },
        };
      }


      // 未下单公海倒计时
      if (NoOrderCountdownP) {
        NoOrderCountdownP.headerComponentParams = {
          click: (data) => {
            let page = 1;
             this.selectTitle=data.title
            this.selectSort=data.state
            this.onSearch(page);
          },
        };
      }

      // 未跟进公海倒计时
      if (NofollowCountdownP) {
        NofollowCountdownP.headerComponentParams = {
          click: (data) => {
            let page = 1;
             this.selectTitle=data.title
            this.selectSort=data.state
            this.onSearch(page);
          },
        };
      }

      // 未询价公海倒计时
      if (NoInquiredCountdownP) {
        NoInquiredCountdownP.headerComponentParams = {
          click: (data) => {
            let page = 1;
             this.selectTitle=data.title
            this.selectSort=data.state
            this.onSearch(page);
          },
        };
      }
      }
      const newColumns = await this.sort(columns, TableName)
      this.$refs.list.changeColumns(newColumns);
    },

    setButtons(params) {
      let data = params.data;
      let buttons=[];
      let self = this;
      const detailBtn = {
        text: "详情",
        click() {
          self.onOpenDetail(data);
        },
      };
      const { CompanyID } = data;
      const editOrderBtn = {
        text: "编辑",
        click() {
          self.$router.push({
            name: "editClient",
            params: {
              clientId: CompanyID,
            },
            query: {
              id: CompanyID,
            },
          });
        },
      };
      const editBtn = {
        text: "写跟进",
        click() {
          self.onOpenEdit(data);
        },
      };

      buttons.push(detailBtn);
      
      // this.can("client_manage_list:edit").then(res => {
      //   if (res) {
      //     buttons.push(editOrderBtn);
      //   }
      // })
       
      // this.can("client_manage_list:write_follow").then(res => {
      //   if (res) {
      //    buttons.push(editBtn);
   
      //   }
      // })
      const isEditBtn=this.can("client_manage_list:edit")
      if (isEditBtn) buttons.push(editOrderBtn);
      const isFollowBtn=this.can("client_manage_list:write_follow")
      if (isFollowBtn) buttons.push(editBtn);

      return buttons;

    },
    onOpenDetail(data) {
      const id = data.CompanyID;
      this.$router.push({
        name: "detailClient",
        params: {
          clientId: id,
        },
        query: {
          id: id,
        },
      });
    },
    async onOpenEdit(data) {
      const id = data.CompanyID;
      this.$router.push({
        name: "followClient",
        params: {
          clientId: id
        },
        query: {
          id: id,
        },
      });
    },

    onCellClicked(params) {
      const { colDef, data } = params;
      const field = colDef.field;
      if (field === "EmergencyLevel") {
        this.$refs.rateChangeView.onOpen(data);
      }
    },
    async onColumnMoved(col) {
      if (col.column) {
        const { field, ColumnCode } = col.column.colDef
        const columns = this.$refs.list.$refs.table.columnDefs
        const localColumns = columns.map(a => { return { ...a, ColumnCode: a.field, ColumnName: a.headerName } })
        const newColumns = [...localColumns]
        const index = newColumns.findIndex(a => a.ColumnCode === (field || ColumnCode))
        if (index >= 0) {
          const _col = newColumns[index]
          newColumns.splice(index, 1)
          newColumns.splice(col.toIndex,0, _col)
          await this.onUserColumnMoved(newColumns, TableName)
        }
      }
    },
    onCellDoubleClicked(params) {
      const { colDef, data } = params;
      const field = colDef.field;
      if (
        field !== "operation" &&
        field !== "rowIndex" &&
        field !== "EmergencyLevel"
      ) {
        this.onOpenDetail(data);
      }
    },
  },
  async created() {
    this.onInit();
    this.onSearch();
  },

  async mounted() {
    await this.initColumns();
  },
};
</script>

<style lang="scss">
@import "./style/listView";
.elbtn
{
   margin-left: 10px;
   margin-right: 10px;
}
</style>
