const followMap = {
  followType: 'FollowUpType',
  followDate: 'FollowUpTime',
  followIsOrder: 'IsReadyToOrder',
  followPredictDate: 'ExpectedOrderTime',
  followNextDate: 'NextFollowUpTime',
  followRemark: 'FollowUpContent',
}
const followContactMap = {
  ContactId:'ContactId',
  contactName: 'ContactName',   // 联系人名称
  contactGender: 'Gender', // 联系人性别
  contactBirthday: 'Birthday', // 联系人生日
  contactPhone: 'MobilePhone',   // 联系人手机
  contactMobilePhone: 'Mobile',   // 联系人电话
  contactQQ: 'QQ',  // 联系人QQ
  contactWechat: 'WeChat', // 联系人微信
  contactWW: 'WangWang',   // 联系人旺旺
  contactEmail: 'Email', //联系人邮箱
  contactSalesQQ: 'ConnectQQ', // 联系对接业务员QQ
  contactSalesWechat: 'ConnectWeChat', // 对接业务员微信
  contactSalesWW: 'ConnectWangWang', // 对接业务员旺旺
  contactSalesEmail: 'ConnectEmail', // 对接业务员邮箱
  contactIdentityZ: 'IDCardPath1', // 身份证正面
  contactIdentityF: 'IDCardPath2', // 身份正反面
  contactTagList: 'CompanyLabelLines'
}
const inquireColumns = [
  {
    field: 'SpeciModel',
    headerName: '规格型号',
    editable: false,
    width: 200,
    cellClass: 't-c',
  },
  {
    field: 'Pack',
    headerName: '封装',
    editable: false,
    width: 140,
    cellClass: 't-c',
  },
  {
    field: 'Brand',
    headerName: '品牌',
    editable: false,
    cellClass: 't-c',
  },
  {
    field: 'Qty',
    headerName: '数量',
    editable: false,
    width: 100,
    cellClass: 't-c',
  },
  {
    field: 'Quote',
    headerName: '采购报价',
    editable: false,
    width: 120,
    cellClass: 't-c',
  },
  {
    field: 'Timequotes',
    headerName: '报价时间',
    editable: false,
    width: 140,
    cellClass: 't-c',
  }
		,
		{
				field: 'InquirePhoto',
				headerName: '询价图片',
				editable: false,
				width: 140,
				cellRendererFramework: 'GridCellFileRender',
				cellClass: 't-c',
		}
		,
		{
				field: 'CreateTime',
				headerName: '创建时间',
				editable: false,
				width: 140,
				cellClass: 't-c',
		}
		,
		{
				field: 'FileRemark',
				headerName: '附件备注',
				editable: false,
				width: 200,
				cellRendererFramework: 'GridCellShowRender',
				cellClass: 't-c',
		}
]
export {
  followMap,
  followContactMap,
  inquireColumns
}
