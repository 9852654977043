<template>
  <div :id="`c-select-input_${item.itemCode}`" class="c-select-input" @mouseenter="onEnter" @mouseleave="onLeave">
    <div :class="isShow? 'c-select-input_box-enter' : ''" :style="{ zIndex: item.zIndex + 1 }"
         class="c-select-input_box">
      <span class="c-select-input_box-title">{{ item.itemName }}</span>
      <i v-if='isShow' class="el-icon-arrow-up"></i>
      <i v-else class="el-icon-arrow-down"></i>
    </div>
    <div v-if='isShow' :style="{ zIndex: item.zIndex}" class="c-select-input_select-box">
      <div v-if="!isCheckboxBox" class="c-select-input_select-box-content">
        <span v-for="(line) in item.lines" :key='line.lineId' @click="onSelectItem(item, line)">{{line.lineName}}</span>
        <i class="c-option" @click="onCheckboxBox">多选</i>
      </div>

      <div v-else  class="c-select-input_select-box-content">
        <el-checkbox-group v-model="checkBoxList">
          <el-checkbox v-for="line in item.lines" :label="line.lineId" :key="-(line.lineId)">{{line.lineName}}</el-checkbox>
        </el-checkbox-group>
        <div class="c-select-input_select-box-content_save">
          <el-button type="primary" :disabled="!checkBoxList.length" @click="onSelectItems(item)">确认</el-button>
          <el-button @click="isCheckboxBox = false">取消</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SelectInput',
  props: {
    item: {
      type: Object
    }
  },
  data() {
    return {
      isShow: false,
      isCheckboxBox: false,
      checkBoxList: []
    }
  },
  methods: {
    onEnter() {
      this.isShow = true
      this.item.zIndex = this.item.zIndex + 20
    },
    onLeave() {
      this.isShow = false
      this.item.zIndex = this.item.zIndex - 20
    },
    onCheckboxBox() {
      this.isCheckboxBox = true
    },
    onSelectItem(item, line) {
      const newItem = Object.assign({}, item)
      newItem.lines = newItem.lines.filter(m => m.lineId === line.lineId)
      this.$emit('selectItems', newItem)
    },
    onSelectItems(item) {
      const {checkBoxList} = this
      const newItem = Object.assign({}, item)
      newItem.lines = newItem.lines.filter(item => checkBoxList.includes(item.lineId))
      this.$emit('selectItems', newItem)
    }

  },
  created() {

  }
}

</script>
<style lang="scss" scoped>
.c-select-input {
  i {
    font-style: normal;
  }

  height: auto;
  min-width: 130px;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;

  .c-select-input_box {
    min-width: 130px;
    padding: 6px 4px;
    color: #cccccc;
    border: 1px solid #E6E6E6;
    background: #fff;
    text-align: center;
    font-size: 13px;
    position: relative;
    cursor: pointer;

    .c-select-input_box-title {
      display: inline-block;
      margin-right: 10px;
      width: auto;
    }
  }

  .c-select-input_box-enter {
    border: 1px solid #999999;
    border-bottom: medium none;

  }

  .c-select-input_select-box {
    font-size: 13px;
    width: auto;
    max-width: 800px;
    display: inline-block !important;
    border: 1px solid #999999;
    background: #fff;
    padding: 8px 30px 8px 8px;
    min-height: 50px;
    margin-top: -1px;
    position: absolute;
    transform: translateX(-20px);
    line-height: 20px;

    span {
      display: inline-block;
      margin-right: 10px !important;
      cursor: default;
      width: auto;
    }

    i {
      color: red;
      cursor: pointer;
    }

    .c-option {
      position: absolute;
      right: 8px;
      top: 8px;
    }

  }

  .c-select-input_select-box-content {

    .el-checkbox+.el-checkbox {
      margin-left: 0;
      margin-right: 10px;
    }

    .c-select-input_select-box-content_save {
      text-align: right;
    }
  }
}
</style>
