<template>
  <div class="c-change-rate-type">
    <el-dialog
      v-el-drag-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="visible"
      @dragDialog="handleDrag">

      <section>
        <p class="m-margin--top-10">
          <span class="m-inline-block" style="width: 90px">客户名称: </span>
          <span class="c-change-client-type_content">{{ editItems.clientName }}</span>
        </p>

        <p class="m-margin--top-10">
          <span class="m-inline-block" style="width: 90px">客户紧急度: </span>
          <rate-view :count="editItems.count" @onSelectRate="onSelectRate" class="m-inline-block"></rate-view>
        </p>


      </section>
      <section class="m-margin--top-10 m-dialog-footer">
        <el-button type="primary" @click="onSave">保存</el-button>
        <el-button @click="visible = false">取消</el-button>
      </section>
    </el-dialog>
  </div>
</template>
<script>
import elDragDialog from "@/directive/el-drag-dialog"; // base on element-ui
import {changeRateType} from "@/api/client";
import RateView from '@/components/Rate'

const dataMap = {
  id: 'CompanyID',
  clientName: 'CompanyName',
  count: 'EmergencyLevel',
}
export default {
  name: 'ChangeTypeClient',
  directives: {
    elDragDialog
  },
  components: {
    RateView
  },
  data() {
    return {
      visible: false,
      title: '更改客户紧急度',
      editItems: {
        id: '',
        clientName: '',
        count: '',
      },
    }
  },
  methods: {
    onOpen(data) {
      this.editItems = this.setDataMap(data, dataMap, 'take')
      this.visible = true
    },
    onSelectRate(count) {
      this.editItems.count = count
    },
    async onSave() {
      const {editItems} = this

      await changeRateType({
        Id: editItems.id,
        EmergencyLevel: editItems.count
      })

      this.$message.success('客户类型修改成功')
      this.$emit('onSearch')
      this.visible = false
    },
    handleDrag() {

    }
  },
  created() {

  }

}
</script>
<style lang="scss">
.c-change-rate-type {

  .el-dialog {
    width: 400px !important;
    min-width: 400px !important;
  }

  .znl-input-title {
    font-size: 14px;
    color: #606266;
    font-weight: normal;
  }

  .c-change-client-type_content {
    color: #000000;
    font-size: 12px;
  }
}
</style>
