<template>
  <div class="c-rate-view">
    <section>
      <svg-icon
        v-for="item in rateItems"
        :key="item.key"
        :icon-class="item.isSelect? 'rate-select': 'rate-default'"
        class="c-rate c-rate-default"
        @click="onIconClick(item)">
      </svg-icon>
    </section>
  </div>
</template>

<script>
export default {
  name: 'RateView',
  props: ['count'],
  data() {
    return {
      rateItems: [
        {
          key: 1,
          isSelect: false,
        },
        {
          key: 2,
          isSelect: false,
        },
        {
          key: 3,
          isSelect: false,
        },
        {
          key: 4,
          isSelect: false,
        },
        {
          key: 5,
          isSelect: false,
        },

      ]
    }
  },
  methods: {
    onIconClick(item) {
      const {rateItems} = this
      const selectItems = rateItems.filter(item => item.isSelect)
      item.isSelect = !item.isSelect
      if(!(selectItems.length === 1 && item.key === 1)) {
        rateItems.forEach(m => {
          m.isSelect = m.key <= item.key;
        })
      }
      const configItems = rateItems.filter(item => item.isSelect)
      this.$emit('onSelectRate', configItems.length)
    }
  },
  watch: {
    count: {
      handler(val) {
        const newCount = Number(val)
        this.rateItems.forEach(m => {
          m.isSelect = m.key <= newCount;
        })
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
<style lang="scss">
.c-rate-view {

  .c-rate {
    font-size: 26px;
    width: 1.5em;
    height: 1em;
  }

  .c-rate-default {
    color: #999999;
  }
}
</style>

