import {inquireColumns} from "../FollowClient/dataMap";
const editItemMap = {
  // 客户信息
  clientCode: 'CompanyCode', // 客户编码
  clientName: 'CompanyName', // 客户名称
  clientCustomerPhone: 'CustomerPhone',    // 客户电话
  clientCustomerQQ: 'CustomerQQ', // 客户QQ
  clientPhone: 'Phone',    // 固定电话
  clientFax: 'Fax',          // 传真
  clientShortName: 'ShortName', // 简称
  clientProvince: 'ProvinceCuster',  // 客户省
  clientCity: 'CityCuster', // 客户市
  clientArea: 'AreaCuster', // 客户区
  clientAddress: 'AdressCuster', // 客户地址
  clientReceiveProvince: 'Province', // 收货省
  clientReceiveCity: 'City',   // 收货市
  clientReceiveArea: 'Area',   // 收货区
  clientReceiveAddress: 'Adress', // 收货地址
  clientResume: 'Introduction', // 客户简历
  clientRemark: 'Remark', // 客户备注
  OrgName: 'OrgName', // 所属机构
  UserName: 'UserName', // 所属人员
  DeptName: 'DeptName', // 所属部门
  clientIsUse: 'IsActive',
  clientOrderCount: 'OrderCount',
  clientFollowCount: 'FollowUpCount',
  clientCommonCount: 'OpenSeaCount',
  clientEnquiryCount: 'InquireCount',
  clientFollowWeekCount:'FollowUpWeekCount',
  clientFollowMonthCount:'FollowUpMonthCount',
  clientFollowSeasonCount:'FollowUpSeasonCount',
  clientCommonMonthCount:'OpenSeaMonthCount',
  clientCommonWeekCount:'OpenSeaWeekCount',
  clientCommonSeasonCount:'OpenSeaSeasonCount',


        //出库（利润）金额
        Amount:'Amount',
        ProfitAmount:'ProfitAmount',
        ThisMonthAmount:'ThisMonthAmount',
        ThisMonthProfitAmount:'ThisMonthProfitAmount',
        ThisQuarterAmount:'ThisQuarterAmount',
        ThisQuarterProfitAmount:'ThisQuarterProfitAmount',
        ThisYearAmount:'ThisYearAmount',
        ThisYearProfitAmount:'ThisYearProfitAmount',

  // 对应得分项的字段
  clientCompaneyAddress: 'CompaneyAddress',
  clientReceivingAddress: 'ReceivingAddress',

  // 联系人信息
  // contactList: 'CusterContactsSend',


  // 财务信息
  financeCapital: 'RegisteredCapital',//注册资金
  financeScope: 'BusinessScope', // 经营范围
  financeLicense: 'BusinessLicense', // 营业执照号
  financeVATNumber: 'VATNumber', //增值税号
  financeDGName: 'CorporateName', // 对公名称
  financeDGAccount: 'CorporateAccount', // 对公账户
  financeBankPhone: 'OpenAccountPhone', // 开户电话
  financeBankName: 'BankName',  // 开户银行
  financeAddress: 'BankAddress', // 开户地址
  financeDebt: 'CreditLimits', // 欠款额度
  financeIsInvoice: 'IsInvoice', //是否开票
  financeFrom: 'ClearingForm', // 结算方式
  financeFromDate: 'ClearingFormDay', // 结算方式天数
  financeSendAddress: 'TaxAddress', //寄送地址
  financeTaxRate: 'TaxRate', // 税率
  financeCurrency: 'CurrencyCode', //结算币种
  financeInvoiceRemark: 'BillingRemark', // 开票备注
  financeNewQuota: 'LatestCreditLimits', // 最新额度
  financeRemainQuota: 'RemainingCreditLimits', // 剩余额度
  financeReceiveQuota: 'PreCreditLimits', // 预收款额度

  // 属性信息
  // attributeList: 'AttributeInfoSend'
}
const contactMap = {
  contactId: 'ContactId', // 联系人名称Id
  contactName: 'ContactName',   // 联系人名称
  contactGender: 'Gender', // 联系人性别
  contactBirthday: 'Birthday', // 联系人生日
  contactPhone: 'MobilePhone',   // 联系人手机
  contactMobilePhone: 'Mobile',   // 联系人电话
  contactQQ: 'QQ',  // 联系人QQ
  contactWechat: 'WeChat', // 联系人微信
  contactWW: 'WangWang',   // 联系人旺旺
  contactEmail: 'Email', //联系人邮箱
  contactSalesQQ: 'ConnectQQ', // 联系对接业务员QQ
  contactSalesWechat: 'ConnectWeChat', // 对接业务员微信
  contactSalesWW: 'ConnectWangWang', // 对接业务员旺旺
  contactSalesEmail: 'ConnectEmail', // 对接业务员邮箱
  contactIdentityZ: 'IDCardPath1', // 身份证正面
  contactIdentityF: 'IDCardPath2', // 身份正反面
  contactTagList: 'CompanyLabelLines'
}

const attributeMap = {
  itemCode: 'ItemCode',
  selectValue: 'ItemValue'
}

const followListMap = {
  followDate: 'FollowUpTime',
  followName: 'ContactName',
  followType: 'FollowUpType',
  followNextDate: 'NextFollowUpTime',
  followOrderDate: 'ExpectedOrderTime',
  followRemark: 'FollowUpContent'
}

//排序数据源
const sortMap=[
  {
    key:1,
    value:"按创建时间降序"
  },
  {
    key:2,
    value:'按创建时间升序'
  },
  {
    key:3,
    value:"按更新时间降序"
  },
  {
    key:4,
    value:'按更新时间升序'
  },
]

//  跟进记录列头配置
const followColumns = [
  // {
  //   field: 'rowIndex',
  //   headerName: '序号',
  //   width: 70,
  //   editable: true,
  //   cellClass: 't-c'
  // },

  {
    field: 'CreatedTime',
    headerName: '跟进日期',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'CreatedEmpName',
    headerName: '跟进人',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'FollowUpType',
    headerName: '跟进方式',
    editable: false,
    cellClass: 't-c',
    // valueFormatter: ({ data: { FollowUpType } }) => {
    //   let ret = ''
    //   switch (FollowUpType){
    //     case 'QQContact':
    //       ret='QQ'
    //       break;
    //     case 'PhoneVisit':
    //       ret='电话'
    //       break;
    //     case 'ComeVisit':
    //       ret='上门'
    //       break;
    //     case 'MailContact':
    //       ret='邮件'
    //       break;
    //   }
    //   return ret;
    // }
  },
  {
    field: 'IsReadyToOrder',
    headerName: '是否准备下单',
    editable: false,
    cellClass: 't-c',
    valueFormatter: ({ data: { IsReadyToOrder } }) => {
      let ret = ''
      switch (IsReadyToOrder){
        case true:
          ret='是'
          break;
        case false:
          ret='否'
          break;
      }
      return ret;
    }
  },
  {
    field: 'ExpectedOrderTime',
    headerName: '预计下单时间',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'NextFollowUpTime',
    headerName: '计划下次跟进时间',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'FollowUpContent',
    headerName: '跟进内容',
    editable: false,
    width: 300,
		  cellRendererFramework: 'GridCellShowRender',
    cellClass: 't-c',
    cellRendererFramework: 'GridCellTextOverflow',
  }
]


export {
  editItemMap,
  contactMap,
  attributeMap,
  sortMap,
  followListMap,
  followColumns,
  inquireColumns
}
