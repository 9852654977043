<template>
  <div class="c-change-client-type">
    <el-dialog
        v-el-drag-dialog
        :close-on-click-modal="false"
        :title="title"
        :visible.sync="visible">
      <section>
        <p class="m-margin--top-10">
          <znl-input
              v-model="UserId"
              :select-options="systemUser"
              border
              form-type="select"
              title="客户归属人: "
              title-width="100px"
              width="350px">
          </znl-input>
        </p>
      </section>
      <section class="m-margin--top-10 m-dialog-footer">
        <el-button type="primary" @click="onSave">保存</el-button>
        <el-button @click="visible = false">取消</el-button>
      </section>
    </el-dialog>
  </div>
</template>
<script>
import elDragDialog from "@/directive/el-drag-dialog"; // base on element-ui
import {changeCompanySales} from "@/api/client";

export default {
  name: 'ChangeCompanySales',
  directives: {
    elDragDialog
  },
  data() {
    return {
      visible: false,
      title: '更改客户归属人',
      CompanyIds: [],
      UserId: 0,
    }
  },
  computed: {
    systemUser() {
      const data = this.$store.state.common.SystemUser;

      if (data) {
        return data.map((item) => {
          return {
            key: item.UserID,
            value: item.UserName,
          };
        });
      } else {
        return [];
      }
    }
  },
  methods: {
    onOpen(CompanyIds) {
      this.UserId = 0;
      this.CompanyIds = CompanyIds
      this.visible = true
    },
    async onSave() {
      const { CompanyIds, UserId } = this
      const params = { CompanyIds, UserId }
      await changeCompanySales(params)
      this.$message.success('更改成功')
      this.$emit('onSearch')
      this.visible = false
    }
  },
  created() {
  }
}
</script>
<style lang="scss">
.c-change-client-type {

  .el-dialog {
    width: 400px !important;
    min-width: 400px !important;
  }

  .znl-input-title {
    font-size: 14px;
    color: #606266;
    font-weight: normal;
  }

  .c-change-client-type_content {
    color: #000000;
    font-size: 12px;
  }
}
</style>
